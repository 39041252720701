<template>
  <a-dialog-form :value="value" @input="close()">
    <v-card>
      <v-card-title>Импорт данных промокодов</v-card-title>
      <v-card-text>
        <v-file-input
          chips
          v-model="file"
          label="Выберите файл импорта"
        ></v-file-input>
        <div>
          <v-progress-linear color="blue-grey" height="25">
            <template v-slot:default>
              <strong>{{ Math.ceil(progress) }}%</strong>
            </template>
          </v-progress-linear>
        </div>
      </v-card-text>
      <v-card-actions>
        <a-btn-form-close @click="close()" />
        <a-btn-form-save @click="submit()">Импорт</a-btn-form-save>
      </v-card-actions>
    </v-card>
  </a-dialog-form>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      loading: false,
      file: null,
      progress: 0,
    };
  },
  created() {
    this.init();
  },
  watch: {
    value() {
      this.init();
    },
  },
  methods: {
    init() {
      this.loading = false;
      this.file = null;
      this.progress = 0;
    },
    close() {
      this.view = 1;
      this.$emit("input");
    },
    async submit() { 
      await this.upload(this.$root.api.cardItemPromoImport, this.file);
      this.init();
      this.$root.$emit("show-info", {
        text: "Загрузка выполнена",
      });
    },
    upload(api, file) {
      return new Promise((reslove) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        this.$axios
          .post(api, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              this.progress = parseInt(
                (progressEvent.loaded / progressEvent.total) * 100
              );
            },
          })
          .then((response) => {
            reslove(response.data);
          })
          .catch(function () {
            console.log("FAILURE!!");
            reslove(false);
          });
      });
    },
  },
};
</script>